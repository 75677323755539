.loginstuff {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /*
  background-color: #f5f5f5;*/
}

 .loginstuff ion-tab-bar {
  display: none;
}

/* Headings */
.loginstuff h2, h1 {
  margin-top: 0;
  font-size: 36px;
  text-align: center;
  color: #000000;
}

.loginstuff label {
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #000000;
}

.loginstuff input[type="text"] {
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  font-size: 16px;
  background-color: #ffffff;
  color: #000000;
  border-color: #000000;
}


.loginstuff input[type="email"],
.loginstuff input[type="password"] {
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  font-size: 16px;
  background-color: #ffffff;
  color: #000000;
  border-color: #000000;
}

.loginstuff p[type="errormessage"] {
  color: red;
}

.loginstuff button {
  background-color: #2196f3;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 10px 20px;
  margin-top: 20px;
}

.loginstuff button:hover {
  background-color: #0d8bf2;
}

.loginstuff p {
  font-size: 18px;
  text-align: center;
  color: #666;
  margin: 18px;
}

.tab3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*
  background-color: #f5f5f5;*/
  text-align: center; /* add this line */
}

.tab3 p {
  color: rgb(0, 0, 0);
}



.tab3 button {
  background-color: #f32121;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 20px;
  margin-top: 20px;
}

.appbackground {
  background-color: #ffffff;
  
}
