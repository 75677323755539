
  
  .leaflet-container {
    width: 100%;
    height: 100%;
    z-index: 1000;
    
    }

    
    .leaflet-control-zoom {
      border-radius: 12%;
      overflow: hidden;
      
    }

.leaflet-control-layers {
  border-radius: 50%;
  overflow: hidden;

}

.leaflet-control-layers-expanded{
  border-radius: 20%;
  overflow: hidden;
}