ion-content {
    --ion-scrollbar-color: transparent;
  }

  #image{
    width:400px;
    height:175px;
    
    border-radius: 50%;
    object-fit: cover;
  }
  .menu-inner {
    border-radius: 0 1.7rem 1.7rem 0!important;
  }
  ion-menu::part(container) { 
    border-radius: 0px 20px 20px 0;
  }

  .tab2 p {
    color: rgb(0, 0, 0);
  }

  .tab2 h6 {
    color: rgb(0, 0, 0);
  }

  .tab2 h1 {
    color: rgb(0, 0, 0);
  }

  .tab2 li {
    color: rgb(0, 0, 0);
  }