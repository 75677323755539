.poster-page {
  overflow: auto;
  background-color: #ffffff;
}

.poster-page body {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  padding: 2%;
}

.poster-page h1 {
  font-size: 20px;
  font-weight: bold;
  margin: 16px 0;
  text-align: center;
}

.poster-page h6 {
  font-size: 16px;
  font-weight: bold;
  margin: 12px 0;
}

.poster-page p { 
  font-size: 14px;
  margin: 8px 0;
}

.poster-page a {   /*For Lenker*/
  color: blue;
  text-decoration: underline;
}

.poster-page ion-button {
  color: rgb(255, 255, 255); /*Farge på skrift i knappene*/
  font-size: 10px; /* Smaller font size */
  font-weight: bold; /*Bold font tekst*/
  border-radius: 8px; /*runde kanter på knapp*/
  margin: 3px 6px; /* Smaller margin */
  padding: 2px; /* Smaller padding */
  display: inline-block;
  display: flex;
  justify-content: space-between;
}

.button-wrapper {
  display: inline-block;
  display: flex;
  justify-content: space-between;
}

.button-wrapper ion-button {
  font-size: 10px;
  font-weight: bold;
  border-radius: 8px;
  padding: 3px 6px;
  margin: 2px;
}

.button-wrapper-right ion-button {
  float: right;
}










/* Media queries
@media screen and (max-width: 768px) {
  .poster-page .button-container ion-button {
    
  }
}



@media screen and (max-width: 576px) {
  .poster-page .button-container {
    
  }

  .poster-page .button-container ion-button {
   
  }
}
 */
 /* Telefoner med bredde på under 280 px*/
 /*
@media screen and (max-width: 280px) {
  
  .poster-page .button-container ion-button {
    font-size: 10px;
  }

  
  
}*/
 